@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&family=Poppins:wght@400;500;600;700&display=swap");

body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  background: #020c1b;
}
